<template>
	<div>
		<div class="upcoming-at-the-park header-catch" style="min-height: 200px;">
			<div class="wrapper">
				<div class="upcoming-header">
					Become a Sponsor
				</div>
				
			</div>
		</div>
		<div class="main-content sponsor">
			<div class="wrapper">
			
				<img src="/assets/img/7.jpg" width="100%" alt="">
				<div class="text-wrapper">
					<p><b>Henning Park</b> is proud to offer businesses and individuals the opportunity to become a part of the park's history by sponsoring a diamond or team. Every dollar that is paid towards these sponsorship options goes directly into the park, ensuring that it remains a vibrant and thriving community hub for years to come.</p>

					<p>There are several sponsorship options available, including diamond sponsorships, team sponsorships, and event sponsorships. Each option provides different levels of exposure and benefits for your business.</p>

					<p>If you or your business is interested in becoming a sponsor at Henning Park, please don't hesitate to <b>reach out to Park President John Uildersma at <a href="mailto:johnnyu99@rogers.com">johnnyu99@rogers.com</a></b>. He would be more than happy to discuss the available options and help you find the perfect sponsorship package to suit your needs.</p>

					<p><b>By becoming a sponsor at Henning Park, you'll be helping to support your community and ensure that this important community hub remains a vital part of Caledonia for years to come.</b></p>
				</div>
				<h1>Sponsored Image on Website - $150/year</h1>
				<p>The Henning Park website hosts all league information for our four leagues, as well as showcases all tournaments. Because of this, we receive a lot of foot-traffic from players interested in their league schedule, standings, or potential tournaments to sign up for.</p>
				<p>Our leagues host 500+ Caledonia, Dunnville and Hamilton players and our tournaments feature upwards of 240 players per weekend.</p>
				<p>With the season running typically from May to September, this offer is ultimately $1 a day and <b>helps us improve the park.</b></p>
				<p>Sponsorship links will click through to your website on a new tab/window.</p>
				<p>To inquire about sponsoring, <b>please contact Park President John Uildersma at <u>johnnyu99&#64;rogers.com.</u></b></p>

				<h1>Sponsored 4x8 Banner on Diamond - $300/year + one-time cost of banner printing</h1>
				<p>As mentioned above, our leagues host 500+ Caledonia, Dunnville and Hamilton players and our tournaments feature upwards of 240 players per weekend.</p>
				<p>Your board will be placed on the diamond of your choice and will be seen by thousands a player in any given season.</p>
				<p>With the season running typically from May to September, this offer is ultimately $2 a day and <b>helps us improve the park.</b></p>
				<p>To inquire about sponsoring, <b>please contact Park President John Uildersma at <u>johnnyu99&#64;rogers.com.</u></b></p>


				<h1>Donations</h1>
				<p>Henning Park thrives on personal and business donations.</p>
				<p>We can write official donation receipts.</p>
				<p>To donate, <b>please contact Park President John Uildersma at <u>johnnyu99&#64;rogers.com.</u></b></p>
				<div id="adarea">
					<scriptx async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7578943240214333"
						crossorigin="anonymous"></scriptx>
					<!-- Henning Park -->
					<ins class="adsbygoogle"
						style="display:block"
						data-ad-client="ca-pub-7578943240214333"
						data-ad-slot="9653716864"
						data-ad-format="auto"
						data-full-width-responsive="true"></ins>
					<scriptx>
						(adsbygoogle = window.adsbygoogle || []).push({});
					</scriptx>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

export default {
	data() {
		return {
			test: null
		};
	},
	components: {

	},
	watch: {
		
	},
	mounted: function() {
		var vm = this
		
		
	},
	computed: {
	
	},
	methods: {
	
	}
}
</script>

<style lang="scss">
    // @import './styles/Welcome.scss';
</style>
